import { render, staticRenderFns } from "./KTPassLanding.vue?vue&type=template&id=48624114"
import script from "./KTPassLanding.vue?vue&type=script&lang=js"
export * from "./KTPassLanding.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports