<template>
    <landing-slot>
        <!--############## Section1 ##############-->
        <template v-slot:section_title> 차량 특화 음악 큐레이션 서비스 </template>
        <template v-slot:section1_imggroup>
            <img src="/media/img/landing/ktmypass/ktpass_aplayz_mobile.png" id="aplayz_mobile" alt="aplayz_mobile" />
            <img src="/media/img/landing/ktmypass/ktpass_aplayz_carplay.png" id="aplayz_carplay" alt="aplayz_carplay" />
        </template>
        <!--############## Section2 ##############-->
        <template v-slot:section2_title> KT PASS 마이데이터 이용 고객님! </template>
        <template v-slot:section2_icon1>
            <img class="car_icon_img" src="/media/img/landing/hynudai/car_icon.png" alt="car_icon" />
        </template>
        <template v-slot:section2_desc1>
            매일 타고 다니는 소중한 내 차, <br />
            색다른 분위기가 필요하신가요?
        </template>
        <template v-slot:section2_icon2>
            <img class="music_icon_img" src="/media/img/landing/hynudai/music_icon.png" alt="car_icon" />
        </template>
        <template v-slot:section2_desc2>
            꽉꽉 막힌 도로 위, <br />
            셀 수 없이 많은 차량에 스트레스 받고 있으신가요?
        </template>

        <template v-slot:section2_desc3>
            차량 분위기의 격이 달라지고 <br />
            꽉 막힌 길거리도 스트레스 없는 <br />
            드라이브로 바뀔거에요!
        </template>
        <!--############## Section3 ##############-->
        <template v-slot:section3_title>
            KT PASS 마이데이터 이용 고객님들께 <br />
            <span class="emphasis">어플레이즈 1개월 무료 이용권</span>을 드립니다!
        </template>
        <template v-slot:section3_3cupon_img>
            <img
                class="section3_3cupon_img"
                src="/media/img/landing/ktmypass/ktpass_aplayz_1month_cupon.png"
                alt="6개월이용권"
            />
        </template>
        <!--############## Section4 ##############-->
        <template v-slot:section4_revu>
            <figure class="section4_revu">
                <img class="revu_img" src="/media/img/landing/ktmypass/revu.png" alt="revu" />
            </figure>
        </template>
    </landing-slot>
</template>
<script>
import LandingSlot from '@/slot/landingSlot.vue';
export default {
  name: 'KTPassLanding',
  components: {
    'landing-slot': LandingSlot
  },
  data () {
    return {
      appEle: null
    };
  },

  mounted () {
    this.appEle = document.querySelector('.app');
    this.appEle.classList.add('spacelanding');
  },

  methods: {
    goHome () {
      const route = this.$router.resolve({ name: 'Home' });
      window.open(route.href, '_blank');
    },
    clickAppStore (storeGb = 'GoogleStore') {
      if (storeGb === 'GoogleStore') {
        // 안드로이드
        window.open('https://play.google.com/store/apps/details?id=com.mobileaos.aplayz', 'blank');
      } else if (storeGb === 'AppleStore') {
        // 아이폰
        window.open('https://apps.apple.com/kr/app/aplayz/id6446978790', 'blank');
      }
    }
  },

  destroyed () {
    this.appEle.classList.remove('spacelanding');
  }
};
</script>
